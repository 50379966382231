import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginMessageService  {
  private _errorMessage = new BehaviorSubject('');
  errorMessage$ = this._errorMessage.asObservable();

  setErrorMessage(message: string) {
    this._errorMessage.next(message);
  }

  clearErrorMessage() {
    this._errorMessage.next('');
  }
}
